import "./App.css";
import Footer from "./components/Footer";

import logo from "./assets/logo.png";

function PrivacyPolicy() {
  return (
    <div className="box-border">
      <div className="flex flex-col">
        <div className="p-24">
          <h2 className="text-3xl font-bold mt-5">
            Privatlivspolitik hos Legepladsen
          </h2>
          <h2 className="text-xl font-bold mt-5">Introduktion</h2>
          Når du besøger vores app indsamles der oplysninger om dig, som bruges
          til at tilpasse og forbedre vores indhold. Nedenfor har vi uddybet,
          hvilke informationer der indsamles, deres formål og hvilke
          tredjeparter, der har adgang til dem.
          <h2 className="text-xl font-bold mt-5">Personoplysninger</h2>
          <h4 className="text-md font-bold mt-5">Generelt</h4>
          Personoplysninger er alle slags informationer, der i et eller andet
          omfang kan henføres til dig. Når du benytter vores app indsamler og
          behandler vi en række sådanne informationer. Det sker f.eks. ved alm.
          tilgang af indhold, hvis du tilmelder dig vores nyhedsbrev, deltager i
          konkurrencer eller undersøgelser, registrerer dig som bruger eller,
          øvrig brug af services. Vi indsamler og behandler typisk følgende
          typer af oplysninger: tekniske oplysninger om din computer, tablet
          eller mobiltelefon, dit IP-nummer og geografisk placering, I det
          omfang du selv giver eksplicit samtykke hertil og selv indtaster
          informationerne behandles desuden: Navn, telefonnummer, e-mail og
          adresse. Det vil typisk være i forbindelse med oprettelse af login.
          <h4 className="text-md font-bold mt-5">Sikkerhed</h4>
          Vi behandler dine personoplysninger sikkert og fortroligt i
          overensstemmelse med gældende lovgivning, herunder
          persondataforordningen og databeskyttelsesloven. Dine oplysninger vil
          alene blive anvendt til det formål, de er indsamlet til, og vil blive
          slettet, når dette formål er opfyldt eller ikke længere relevant. Vi
          har truffet tekniske og organisatoriske foranstaltninger mod, at dine
          oplysninger hændeligt eller ulovligt bliver slettet, offentliggjort,
          fortabt, forringet eller kommer til uvedkommendes kendskab, misbruges
          eller i øvrigt behandles i strid med lovgivningen.
          <h4 className="text-md font-bold mt-5">Formål</h4>
          Oplysningerne bruges til at identificere dig som bruger og vi anvender
          oplysningerne til at optimere vores services og indhold.
          <h4 className="text-md font-bold mt-5">Periode for opbevaring</h4>
          Oplysningerne opbevares i det tidsrum, der er tilladt i henhold til
          lovgivningen, og vi sletter dem, når de ikke længere er nødvendige.
          Perioden afhænger af karakteren af oplysningen og baggrunden for
          opbevaring. Det er derfor ikke muligt at angive en generel tidsramme
          for, hvornår informationer slettes.
          <h4 className="text-md font-bold mt-5">
            Videregivelse af oplysninger
          </h4>
          Data om din brug af app´en, geografisk placering, køn og alderssegment
          m.v. videregives til tredjeparter i det omfang disse oplysninger er
          kendt. Vi benytter herudover en række tredjeparter til opbevaring og
          behandling af data bl.a. App-libitum i/s. Disse behandler udelukkende
          oplysninger på vores vegne og må ikke anvende dem til egne formål.
          Videregivelse af personoplysninger som navn og e-mail m.v. vil kun
          ske, hvis du giver samtykke til det. Vi anvender kun databehandlere i
          EU eller i lande, der kan give dine oplysninger en tilstrækkelig
          beskyttelse.
          <h4 className="text-md font-bold mt-5">Indsigt og klager</h4>
          Du har ret til at få oplyst, hvilke personoplysninger, vi behandler om
          dig i et almindeligt format (dataportabilitet). Du kan desuden til
          enhver tid gøre indsigelse mod, at oplysninger anvendes. Du kan også
          tilbagekalde dit samtykke til, at der bliver behandlet oplysninger om
          dig. Hvis de oplysninger, der behandles om dig, er forkerte har du ret
          til at de bliver rettet eller slettet. Henvendelse herom kan ske til:
          legepladsenapp@gmail.com. Hvis du vil klage over vores behandling af
          dine personoplysninger, har du også mulighed for at tage kontakt til
          Datatilsynet. Ønsker du ikke længere, at vi skal behandle dine
          personoplysninger, eller at vi skal begrænse behandlingen af dine
          personoplysninger, kan du også sende os en anmodning herom til
          ovennævnte e-mailadresse.
          <h4 className="text-md font-bold mt-5">Udgiver</h4>
          App ejes og publiceres af:
          <p>Legepladsen</p>
          <p>H C Ørsteds vej 68</p>
          <p>5700 Sendborg</p>
          <p>Telefon: 22725277</p>
          <p>Email: legepladsenapp@gmail.com</p>
        </div>
        <Footer logo={logo} />
      </div>
    </div>
  );
}

export default PrivacyPolicy;
