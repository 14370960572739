import "./App.css";
import Footer from "./components/Footer";

import logo from "./assets/logo.png";

function TermsOfUse() {
  return (
    <div className="box-border">
      <div className="flex flex-col">
        <div className="p-24">
          <h2 className="text-3xl font-bold mt-5">
            Brugerbetingelser for anvendelse af app'en Legepladsen
          </h2>
          Brugerbetingelserne (version 1.00 af brugerbetingelser) er senest
          opdateret August 2022 og erstatter alle tidligere gældende
          betingelser. Betingelserne er gældende, indtil nye betingelser
          udsendes.s
          <h2 className="text-xl font-bold mt-5">Generelt</h2>
          App'en Legepladsen ejes, drives og administreres af Legepladsen I/S
          CVR nr. 43257366, H C Ørsteds vej 68 5700 Svendborg.
          Brugerbetingelserne gælder for applikationen Legepladsen samt services
          tilknyttet hertil (herefter benævnt ”applikationen” og/eller den
          samlede betegnelse ”Legepladsen”). Det er en betingelse for brugen af
          Legepladsen, at du accepterer brugerbetingelserne. Accepteres
          brugerbetingelserne ikke kan du ikke anvende Legepladsen. Legepladsen
          (i nogle tilfælde Legepladsens samarbejdspartnere eller andre
          tredjeparter) har alle rettigheder, herunder ejendomsret og ophavsret,
          til billeder, mediefiler, tekster m.v., som findes i Legepladsen.
          Indhold må ikke kopieres eller på anden måde eftergøres uden specifik
          skriftlig tilladelse fra Legepladsen.
          <h2 className="text-xl font-bold mt-5">Brugerprofil</h2>
          For at anvende Legepladsen skal du oprette en profil. Oprettelse af en
          profil kræver, at du som minimum angiver din e-mailadresse samt en
          adgangskode. Den profil, du opretter, er til din egen personlige brug.
          Ved oprettelse af en brugerprofil i Legepladsen skal du indtaste en
          personlig adgangskode, som til enhver tid skal behandles fortroligt.
          Du må aldrig gøre din adgangskode tilgængelig for andre. Ved at
          acceptere brugerbetingelserne giver du samtykke til, at Legepladsen må
          sende dig eller på anden vis præsentere dig for målrettet
          markedsføring og reklamer i applikationen. Markedsføring og reklamer
          kan være rettet mod tilkøb i applikationen såvel som markedsføring og
          reklamer fra tredjeparter (andre virksomheder, serviceudbydere,
          partnere, annoncører, reklamebureauer, el.lign.). Personoplysninger er
          alle slags informationer, der i et eller andet omfang kan henføres til
          dig. Når du benytter legepladsen indsamler og behandler vi automatisk
          en række sådanne informationer. F.eks. anonymiseret brugsadfærd,
          statistik, geografisk placering, mv. som primært anvendes til at
          forbedre brugeroplevelsen i fremtidige versioner. Ved oprettelse af
          bruger i Navngiv giver du yderligere samtykke til at vi må behandle
          dit navn og e-mail.
          <h2 className="text-xl font-bold mt-5">Sikkerhed</h2>
          Legepladsen overholder til som minimum følgende sikkerhedskrav:
          <div>
            <ul>
              <li>
                ● Al data, der kommunikeres frem og tilbage imellem Legepladsen
                og din enhed, er krypteret. Det er derfor ikke muligt for
                udefrakommende at tilgå indholdet af datastrømmen imellem dig og
                Legepladsen.
              </li>
              <li>
                ● Legepladsen tager løbende og automatisk backup af alle data i
                Legepladsen.
              </li>
            </ul>
          </div>
          Du er selv ansvarlig for hemmeligholdelse af din adgangskode. I
          tilfælde af misbrug eller mistanke om misbrug af din adgangskode skal
          du straks skifte dit kodeord. Hvis Legepladsen mistænker misbrug af
          din profil, kan vi altid spærre din profil. Legepladsen er i disse
          situationer uden ansvar over for dig eller tredjemand.
          <h2 className="text-xl font-bold mt-5">
            Kommunikation mellem Legepladsen og bruger
          </h2>
          Legepladsen sender dig til tider notifikationer/push-notifikationer på
          din enhed, medmindre du aktivt fravælger dette. Notifikationerne kan
          være generelle, reklameret indhold eller baseret på oplysninger om din
          anvendelse af Legepladsen. Med dit samtykke kan Legepladsen sende
          nyhedsbreve.
          <h2 className="text-xl font-bold mt-5">Rettigheder</h2>
          Legepladsen I/S har samtlige rettigheder til applikationen samt øvrigt
          materiale på Legepladsen platforme, herunder applikation (Legepladsen)
          og hjemmeside (legepladsen-app.dk). Dette omfatter, men er ikke
          begrænset til, ophavsrettigheder, ejendomsret og varemærkerettigheder
          til Legepladsen. Du bevarer rettighederne til materiale, du lægger op
          på Legepladsen, men giver Legepladsenen ikke-eksklusiv brugsret til
          dette materiale. Som bruger får du en ikke-eksklusiv brugsret til
          Legepladsen. Legepladsen kan til enhver tid tilbagekalde denne
          brugsret. Du må som bruger gerne kopiere, distribuere og vise
          materiale fra Legepladsen, forudsat du krediterer med
          ”Legepladsen-app.dk”. Dette omfatter ikke kopiering af applikationen.
          Legepladsen I/S er berettiget til når som helst at overføre, overdrage
          og/eller videregive sine rettigheder til applikationen (f.eks. ved et
          frasalg af applikationen).
          <h2 className="text-xl font-bold mt-5">Drift</h2>
          Legepladsen søger efter bedste evne at undgå fejl, mangler og
          driftsforstyrrelser i applikationen, men dette kan forekomme.
          Legepladsen modtager gerne orientering om sådanne forhold på
          legepladsenapp@gmail.com og vil til enhver tid søge at afhjælpe
          sådanne snarest belejligt. Legepladsen foretager løbende vedligehold
          og opdateringer af applikationen. I forbindelse med vedligehold kan
          visse eller alle funktioner blive midlertidigt utilgængelige. I
          forbindelse med opdateringer kan en eller flere funktioner, herunder
          tilkøb, blive fjernet eller ændret fra Legepladsen. Legepladsen kan
          til enhver tid vælge helt at ophøre eller lukke og nedtage
          applikationen (enten midlertidigt eller permanent). Legepladsen er
          ikke på nogen måde ansvarlig over for sine brugere i tilfælde af
          funktionsfejl, datafejl, mangler, driftsforstyrrelser eller fjernelse
          af funktioner eller data i applikationen.
          <h2 className="text-xl font-bold mt-5">Ophør</h2>
          Hvis du er uenig i brugerbetingelserne eller af anden årsag ikke
          længere ønsker at anvende Legepladsen, kan du til enhver tid slette
          din brugerprofil. Legepladsen kan til enhver tid og uden varsel
          suspendere eller opsige din aftale om brug af Legepladsen og eventuelt
          slette din brugerprofil, f.eks. i tilfælde af uacceptabel
          brugeradfærd, forsøg på misbrug, forsøg på at få adgang til andres
          data eller anden ulovlig eller uanstændig opførsel. Såfremt
          Legepladsen suspenderer eller opsiger din aftale om brug af
          Legepladsen, vil Legepladsen rette henvendelse til dig via den
          e-mailadresse, som du har angivet ved oprettelse af din profil hos
          Legepladsen. Ved sletning af din brugerprofil slettes eller
          anonymiseres alle de data, der er registreret på din brugerprofil i
          Legepladsen permanent og kan ikke genskabes. Du kan ikke rette nogen
          former for krav mod Legepladsen, såfremt din konto slettes.
          <h2 className="text-xl font-bold mt-5">
            Tilbagekaldelse af samtykke
          </h2>
          Du kan til enhver tid tilbagekalde et eller flere af de samtykker, du
          har afgivet over for Legepladsen. Tilbagekaldelse kan ske på ved at
          kontakte legepladsenapp@gmail.com. Såfremt du tilbagekalder et eller
          flere af dine samtykker kan det ikke garanteres at du kan have fortsat
          adgang til din brugerprofil eller vil kunne anvende applikationen.
          <h2 className="text-xl font-bold mt-5">Ansvar</h2>
          Legepladsen I/S kan på intet tidspunkt stilles til ansvar for din brug
          af applikationen. Din brug af Legepladsen samt brug af den
          information, du kommer i besiddelse af ved brug af Legepladsen, er til
          enhver tid på eget ansvar og for egen risiko. Legepladsen er ikke
          ansvarlig for manglende overholdelse af aftalen, skade eller tab i
          tilfælde af force majeure, hvortil henregnes bl.a. virus- eller
          hackerangreb, nedbrud eller fejl i kommunikationssystemer, strømsvigt,
          underleverandørers force majeure samt andre uforudsete omstændigheder,
          som Legepladsen ikke rimeligvis kunne forudse eller undgå. Såfremt
          Legepladsen I/S uanset ovenstående måtte blive erstatningsansvarlig,
          kan Legepladsen I/S samlede erstatningsansvar over for en bruger
          aldrig overstige et beløb svarende til, hvad brugeren måtte have
          betalt til Legepladsen for brugen af Legepladsen inden for de seneste
          12 måneder forud for kravets opståen.
          <h2 className="text-xl font-bold mt-5">Lovvalg og tvister</h2>
          Enhver tvist mellem Legepladsen og brugere skal så vidt mulig søges
          afgjort i mindelighed. Legepladsen er et dansk foretagende og
          underlagt dansk lovgivning. Såfremt en tvist mellem Legepladsen og
          brugeren ikke kan afgøres i mindelighed, skal sådan sag afgøres efter
          dansk ret ved de almindelige danske domstole.
          <h2 className="text-xl font-bold mt-5">
            Ændringer i brugerbetingelser
          </h2>
          Legepladsen kan til enhver tid og uden varsel ændre disse
          brugerbetingelser. Den seneste version findes altid på
          legepladsen-app.dk samt i applikationen. Du orienteres om ændringer af
          betingelserne via den e-mailadresse, du har oplyst i din brugerprofil
          hos Legepladseneller via push-meddelelse i applikationen.
        </div>
        <Footer logo={logo} />
      </div>
    </div>
  );
}

export default TermsOfUse;
