import "./App.css";

import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import Step from "./components/Step";
import BottomLead from "./components/BottomLead";
import Footer from "./components/Footer";
import logo from "./assets/logo.png";
import Rectangle_3 from "./assets/rectangle3.png";
import Rectangle_4 from "./assets/rectangle4.png";
import Rectangle_5 from "./assets/rectangle5.png";

function App() {
  const data = {
    hero: {
      appType: "Legepladsen App",
      tagLine: "Gør jagten på nye legepladser til en leg!",
      description: "Find legepladser i hele Danmark",
      mainActionText: "Google Play",
      extraActionText: "App Store",
    },
    step1: {
      title: "Opret en bruger",
      heading: "Opret en bruger eller log ind for at komme igang",
      description:
        "Du opretter en bruger med email, en ønsket adgangskode og dit navn",
      img: Rectangle_3,
      alternate: false,
    },
    step2: {
      title: "Find legepladser i nærheden af dig",
      heading: "Find legepladser på kort eller ved hjælp af søgefunktionen",
      description:
        "Gå på opdagelse i dit nærområde eller find en legeplads på turen",
      img: Rectangle_4,
      alternate: true,
    },
    step3: {
      title: "Leg",
      heading: "Naviger til legepladsen",
      description:
        "Når du har fundet en legeplads kan du se detaljer om den før du tager derhen og leger",
      img: Rectangle_5,
      alternate: false,
    },
    bottomLead: {
      actionText: "Hent appen nu",
      description: "Hent til iOS og Android.",
      mainActionText: "App Store",
      extraActionText: "Google Play",
    },
  };
  return (
    // __________________________TODO: ____________________
    // Add Montserrat font to everything (body)

    <div className="box-border">
      <div className="flex flex-col">
        <Navbar logo={logo} />
        <Hero
          appType={data.hero.appType}
          tagLine={data.hero.tagLine}
          description={data.hero.description}
          mainActionText={data.hero.mainActionText}
          extraActionText={data.hero.extraActionText}
        />

        <div
          id="divider"
          className="rounded-full ring-2 ring-gray-200 lg:w-1/2 lg:mx-auto "
        ></div>

        <div
          id="app"
          className="pt-20 mb-20 text-3xl font-semibold text-center text-blue-800 lg:font-bold"
        >
          Sådan fungerer appen
        </div>

        <Step
          title={data.step1.title}
          heading={data.step1.heading}
          description={data.step1.description}
          img={data.step1.img}
          alternate={data.step1.alternate}
        />
        <Step
          title={data.step2.title}
          heading={data.step2.heading}
          description={data.step2.description}
          img={data.step2.img}
          alternate={data.step2.alternate}
        />
        <Step
          title={data.step3.title}
          heading={data.step3.heading}
          description={data.step3.description}
          img={data.step3.img}
          alternate={data.step3.alternate}
        />
        <BottomLead
          actionText={data.bottomLead.actionText}
          description={data.bottomLead.description}
          mainActionText={data.bottomLead.mainActionText}
          extraActionText={data.bottomLead.extraActionText}
        />
        <Footer logo={logo} />
        <div className="flex mx-auto w-2/4 justify-center text-center">
          <a href="/privacypolicy" className="flex mx-auto text-xl">
            Privatlivspolitik
          </a>
          <a href="/termsofuse" className="flex mx-auto text-xl">
            Brugsbetingelser
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;
