import React from "react";
import Tooltip from "@mui/material/Tooltip";

function openAppStore(){
  window.open('https://apps.apple.com/dk/app/legepladsen/id6443782255?l=da', '_blank');

}

function openPlay(){
  window.open('https://play.google.com/store/apps/details?id=com.legepladsen.legepladsenapp&fbclid=IwAR2y18mYEShQdkdGGLrRb-zisANS2wDpc5Coz5reLFQeavquQsdfIFztv94&pli=1', '_blank');

}

const BottomLead = ({
  actionText,
  description,
  mainActionText,
  extraActionText,
}) => {
  return (
    <div className="flex flex-col justify-center h-screen lg:pt-24 lg:pb-14 lg:bg-cover lg:h-full">
      <p className="p-5 text-5xl font-bold leading-normal text-center text-white lg:text-4xl lg:pt-10">
        {actionText}
      </p>
      <p
        className="pl-12 pr-12 text-xl leading-8 text-center lg:font-medium"
        style={{ color: "#b8d586" }}
      >
        {description}
      </p>
      <div className="flex flex-col flex-wrap items-center justify-center w-full h-48 pt-5 lg:flex-row lg:pt-1">
        
          <button
            className="pt-3 pb-3 text-2xl font-semibold text-center text-white transition-all rounded-full shadow-2xl lg:mr-5 hover:bg-gray-50 pl-11 pr-11 focus:outline-none  lg:font-medium lg:text-gray-50 lg:bg-opacity-0 "
            style={{ backgroundColor: "#b8d586" }}
            onClick={()=> openAppStore()}
          >
            {mainActionText}
          </button>
        
        
          <button
            className="pt-3 pb-3 text-2xl font-semibold text-center text-white transition-all rounded-full shadow-2xl lg:mr-5 hover:bg-gray-50 pl-11 pr-11 focus:outline-none  lg:font-medium lg:text-gray-50 lg:bg-opacity-0 "
            style={{ backgroundColor: "#b8d586" }}
            onClick={()=> openPlay()}
          >
            {extraActionText}
          </button>
        
      </div>
    </div>
  );
};

export default BottomLead;
