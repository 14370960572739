import React from "react";
import Tooltip from "@mui/material/Tooltip";

import rectangle_1 from "../assets/rectangle.png";

function openAppStore(){
  window.open('https://apps.apple.com/dk/app/legepladsen/id6443782255?l=da', '_blank');

}

function openPlay(){
  window.open('https://play.google.com/store/apps/details?id=com.legepladsen.legepladsenapp&fbclid=IwAR2y18mYEShQdkdGGLrRb-zisANS2wDpc5Coz5reLFQeavquQsdfIFztv94&pli=1', '_blank');

}

const Hero = ({
  appType,
  tagLine,
  description,
  mainActionText,
  extraActionText,
}) => {
  return (
    <div id="product">
      <div
        style={{ textShadow: "0px 2px 2px gray" }}
        className="flex flex-col items-center justify-start font-sans min-h-96 bg-gray-300 bg-bottom bg-blend-darken lg:pt-10 lg:pb-20 bg-hero bg-cover sm:bg-hero sm:bg-cover"
      >
        <div>
          <h2
            className="p-3 pt-12 text-3xl font-bold text-center"
            style={{ color: "#b8d586" }}
          >
            {appType}
          </h2>
        </div>
        <div style={{ textShadow: "0px 2px 2px gray" }}>
          <p className="p-2 text-4xl font-bold text-center lg:mx-auto drop-shadow-lg lg:w-4/6 lg:text-5xl text-stroke-1 text-gray-50">
            {tagLine}
          </p>
        </div>
        <div>
          <p className="p-4 pt-6 font-sans text-2xl leading-10 text-center text-gray-100 lg:text-gray-100">
            {description}
          </p>
        </div>
        <div className="relative z-50 mb-10 flex flex-col items-center justify-between h-48 lg:space-x-8 pt-7 lg:pt-0 lg:flex-row lg:justify-between lg:w-90">
          
            <button
              className="pt-3 pb-3 text-2xl font-semibold text-center transition-all rounded-full shadow-2xl lg:mr-5 hover:bg-gray-50 pl-11 pr-11 focus:outline-none  lg:font-medium text-gray-50 lg:bg-opacity-0 "
              style={{ backgroundColor: "#b8d586" }}
              onClick={()=>openPlay()}
            >
              {mainActionText}
            </button>
            <button
              className="pt-3 pb-3 text-2xl font-semibold text-center transition-all rounded-full shadow-2xl lg:mr-5 hover:bg-gray-50 pl-11 pr-11 focus:outline-none  lg:font-medium text-gray-50 lg:bg-opacity-0 "
              style={{ backgroundColor: "#b8d586" }}
              onClick={()=>openAppStore()}
            >
              {extraActionText}
            </button>
          
        </div>
      </div>
      <div className="z-0 flex flex-row items-start md:-mt-12 lg:-mt-12 justify-center mt-12 w-screen p-4">
        <img
          className="object-cover p-4 z-0 lg:w-1/2 md:-mt-12 lg:-mt-32 xl:w-1/3"
          src={rectangle_1}
          alt=""
        />
      </div>
    </div>
  );
};

export default Hero;
