import React, { useEffect, useState } from "react";
import { Route, Link, Routes, useParams } from "react-router-dom";
import Navbar from "./components/Navbar";
import logo from "./assets/logo.png";
import { TextField, Tooltip } from "@mui/material";
import Footer from "./components/Footer";
import axios from "axios";

function ResetPassword() {
  const params = useParams();
  const uid = params.uid;
  const [matching, setMatching] = useState(true);
  const [password, setPassword] = useState("");
  const [confirm, setconfirm] = useState("");
  const [error, setError] = useState("");
  const [helperText, setHelperText] = useState("");
  const [request, setRequest] = useState("");
  const [success, setSuccess] = useState(null);

  function testMatching() {
    setMatching(password == confirm);
    setHelperText("");
    if (password.length >= 8 && password.length != 0) {
      if (!matching) {
        setHelperText("Adgangskoden skal være ens");
      } else {
        setHelperText("");
      }
    } else {
      if (!matching) {
        setHelperText("Adgangskoden skal være ens");
      }
      setHelperText("Adgangskoden er for kort");
    }
  }

  function onSubmit() {
    if (matching && password.length >= 8) {
      axios
        .put("https://api.legepladsen-app.dk/Auth/resetpassword/" + uid, {
          Uid: request.uid,
          NewPassword: password,
        })
        .then((res) => setSuccess(res.data))
        .catch((e) => setError(e.response.data));
    }
  }
  useEffect(() => {
    testMatching();
  }, [confirm, password, matching]);

  useEffect(() => {
    GetRequest();
  }, []);

  function GetRequest() {
    axios
      .get("https://api.legepladsen-app.dk/Auth/resetpassword/" + uid)
      .then((res) => setRequest(res.data))
      .catch((e) => setError(e.response.data));
  }

  if (params.uid.length < 20) {
    return (<div className="box-border">
    <div className="flex flex-col">
      <div className="flex flex-row items-center justify-between h-28">
        <a href="/" className="pl-7 logo lg:ml-10">
          <img height="25%" width="25%" src={logo} alt="logo" />
        </a>
      </div>
      <div className="flex flex-col justify-center text-center pt-5 lg:bg-cover lg:h-full">
        <h1
          className="text-3xl font-bold pt-5 pb-5"
          style={{ color: "#DC143C" }}
        >
          Beklager, noget gik galt. Gå ind i appen og bed om en nye nulstilling.
        </h1>
      </div>
    </div>
  </div>);
  }

  if (success != null) {
    return (
      <div className="box-border">
        <div className="flex flex-col">
          <div className="flex flex-row items-center justify-between h-28">
            <a href="/" className="pl-7 logo lg:ml-10">
              <img height="25%" width="25%" src={logo} alt="logo" />
            </a>
          </div>
          <div className="flex flex-col justify-center text-center pt-5 lg:bg-cover lg:h-full">
            <h1
              className="text-3xl font-bold pt-5 pb-5"
              style={{ color: "#DC143C" }}
            >
              {error}
            </h1>
            <h2 className="text-3xl font-bold pt-5 pb-5">
              {success}
            </h2>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="box-border">
      <div className="flex flex-col">
        <div className="flex flex-row items-center justify-between h-28">
          <a href="/" className="pl-7 logo lg:ml-10">
            <img height="25%" width="25%" src={logo} alt="logo" />
          </a>
        </div>
        <div className="flex flex-col justify-center text-center pt-5 lg:bg-cover lg:h-full">
          <h1
            className="text-3xl font-bold pt-5 pb-5"
            style={{ color: "#DC143C" }}
          >
            {error}
          </h1>
          <h2 className="text-3xl font-bold pt-5 pb-5">
            Nulstilling af adgangskode
          </h2>
          <div className="flex flex-col flex-wrap items-center justify-center w-full h-48 pt-5 lg:flex-row lg:pt-1">
            <div className="flex flex-col flex-wrap  items-center justify-center pt-5 lg:pt-1">
              <TextField
                id="password"
                label="Ny adgangskode"
                variant="outlined"
                onChange={(e) => setPassword(e.target.value)}
                type="password"
              />
            </div>
            <div className="flex flex-col flex-wrap items-center justify-center w-full pt-5 mt-3">
              <TextField
                id="confirm"
                label="Bekræft adgangskode"
                variant="outlined"
                type="password"
                onChange={(e) => setconfirm(e.target.value)}
                error={!matching}
                helperText={helperText}
              />

              <button
                type="submit"
                className="mt-5 pt-3 pb-3 text-2xl font-semibold text-center text-white transition-all rounded-full shadow-2xl lg:mr-5 hover:bg-gray-50 pl-11 pr-11 focus:outline-none  lg:font-medium lg:text-gray-50 lg:bg-opacity-0 "
                style={{ backgroundColor: "#b8d586" }}
                onClick={() => onSubmit()}
              >
                Gem
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
